/**
 * Configures the font-size utility for the app.
 */
@forward 'Lib/sass/font-utils' with (
  $font-size-multiplier: 1.2
);

@mixin heading-family {
  font-family: 'Playfair Display', serif;
  font-weight: 600;
}

@mixin copy-family {
  font-family: 'Playfair Display', serif;
  font-weight: 400;
}

@mixin copy-family--bold {
  font-family: 'Playfair Display', serif;
  font-weight: 600;
}

@mixin copy-family--italic {
  font-family: 'Playfair Display', serif;
  font-style: italic;
  font-weight: 400;
}
