@use 'App/views/sass/color-utils';

.panel {
  background-color: color-utils.get-color('gunmetal');
  background: linear-gradient(
    170deg,
    color-utils.get-color('gunmetal') 20%,
    color-utils.get-color('asphalt') 80%
  );
  border: thin solid color-utils.get-color('border');
  border-radius: 0.5rem;
  box-shadow: inset 0 0 0.4rem rgba(0, 0, 0, 0.5);
  display: block;
  overflow: hidden;
  padding: 2rem;

  &--accented {
    border-left-color: color-utils.get-color('border:light');
    border-left-style: solid;
    border-left-width: 0.6rem;
    border-radius: 0;
  }
}
