@use 'App/views/sass/color-utils';
@use 'Lib/sass/responsive-utils';

.footer {
  border-top: 1px solid color-utils.get-color('border');
  padding: 2rem 0;

  @include responsive-utils.breakpoint(768) {
    padding: 2rem 0;
  }
}

.footer-content {
  color: color-utils.get-color('copy:dark');
  display: flex;
  justify-content: center;
}
