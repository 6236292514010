@use 'sass:map';

$_breakpoints: (
  375: 375px,
  425: 425px,
  768: 768px,
  1024: 1024px,
  1440: 1440px
);

@mixin breakpoint($breakpoint) {
  @if map.get($_breakpoints, $breakpoint) {
    @media screen and (min-width: map.get($_breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @error "Breakpoint not found.";
  }
}
