@use 'Lib/sass/reset';
@use 'Lib/sass/utils';
@use 'CMS/views/sass/color-utils';

html, body {
  background-color: #111;
  color: color-utils.get-color('copy');
  color-scheme: dark;
  font-family: sans-serif;
  font-size: 100%;
}

.page {
  display: block;
  min-height: 100vh;
  height: auto;
}
