@use 'Lib/sass/utils';
@use 'App/views/sass/color-utils';
@use 'App/views/sass/responsive-utils';

.header {
  border-bottom: 1px solid color-utils.get-color(border);
  padding: 2rem 0 1rem 0;

  @include responsive-utils.breakpoint(768) {
    padding: 2rem 0;
  }
}

.content {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;

  @include responsive-utils.breakpoint(768) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.logo {
  font-size: 2rem;
  margin-bottom: 1rem;
  text-align: center;

  @include responsive-utils.breakpoint(768) {
    margin: 0;
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
}

.list-item {
  padding: 0 1rem 0 0;

  &:last-of-type {
    padding: 0;
  }
}

.link {
  text-decoration: none;
  color: color-utils.get-color('copy:link');

  &:hover {
    text-decoration: underline;
  }
}
