@use 'App/views/sass/font-utils';
@use 'App/views/sass/color-utils';

.tile {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.link-row {
  align-items: flex-end;
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  margin-top: 2rem;
}

.link {
  background-color: color-utils.get-color('gunmetal');
  border: 1px solid color-utils.get-color('border:light');
  border-radius: 2rem;
  box-shadow:
    0 0.2rem 0.6rem rgba(0, 0, 0, 0.6),
    inset 0 0 0.4rem rgba(0, 0, 0, 0.6);
  color: color-utils.get-color('copy:link');
  display: inline-block;
  @include font-utils.copy-family--italic;
  padding: 0.4rem 1.2rem 0.6rem 1.2rem;
  text-decoration: none;

  &:hover, &:focus {
    background-color: color-utils.get-color('gunmetal');
    text-decoration: underline;
  }
}

.title {
  @include font-utils.heading-family;
  font-size: 2rem;
  line-height: 1.2;
  margin-bottom: 0.6rem;
}

.publish-date {
  border-bottom: 1px solid color-utils.get-color('border');
  color: color-utils.get-color('copy:dark');
  display: inline-block;
  @include font-utils.copy-family--italic;
  margin-bottom: 1rem;
  padding-bottom: 1.2rem;
}
