@use 'App/views/sass/responsive-utils';

.articles {
  align-items: stretch;
  display: grid;
  grid-row-gap: 2rem;
  grid-template-columns: 1fr;

  @include responsive-utils.breakpoint(768) {
    grid-column-gap: 2rem;
    grid-template-columns: 1fr 1fr;
  }

  @include responsive-utils.breakpoint(1024) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.article {
  height: 100%;
}
