@use 'App/views/sass/color-utils';
@use 'App/views/sass/font-utils';

/**
 * Styles for large bodies of text, e.g. multiple paragraphs.
 */
.copy {
  color: color-utils.get-color('copy');
  @include font-utils.copy-family();
  font-size: font-utils.size(1);
  line-height: 1.4;

  h1, h2, h3, h4, h5, h6 {
    @include font-utils.heading-family();
  }

  h2 {
    font-size: font-utils.size(1.4);
    margin-bottom: 1rem;
  }

  p {
    line-height: 1.6;
    margin-bottom: 1rem;

    &:last-of-type {
      margin-bottom: 0rem;
    }
  }

  a {
    color: color-utils.get-color('copy:link');
    @include font-utils.copy-family--italic();
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  ul {
    padding: 0;
    margin: 0;
  }

  ul li {
    margin-bottom: 0.4rem;

    &:last-of-type {
      margin: 0;
    }

    &::before {
      content: '-';
      margin-right: 1rem;
    }
  }

  hr {
    border-width: 0;
    border-bottom: 1px solid color-utils.get-color('border');
    margin: 3rem 0;
  }
}
