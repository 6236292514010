@use 'App/views/sass/font-utils';

.default-layout {
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100vh;
  font-size: font-utils.size(1);
}

.main {
  flex-grow: 1;
  padding: 3rem 0;
}
